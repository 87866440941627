import BaseCrudService from "./baseCrud.service";
import ApiService from "./api.service";
import LoginService from "./login.service";

const DASHBOARD_FILTERS = 'dashboard-filters'

export default class CompanyService extends BaseCrudService {

	constructor() {
		super('Company');
	}

	save(callback, company) {
		this.postRequest('Save', company).then(callback);
	}

	find(callback, companyId) {
		return this.getRequest('FindById', { companyId: companyId }).then(callback);
	}

	getCampaignCompaniesFromSameGroup(companyId, campaignId) {
		return this.getRequest('GetCampaignCompaniesFromSameGroup', { campaignId: campaignId, companyId: companyId });
	}

	getFileByEntity(entityId) {
		return this.getRequest("GetFileByEntity", { entityId: entityId }, false, false);
	}

	getBySignedInUser() {
		return this.getRequest('GetBySignedInUser');
	}

	findByEntityId(entityId) {
		return this.getRequest('FindByEntityId', { entityId: entityId });
	}

	listFileCompany(callback, companyId) {
		return this.getRequest('ListFileCompanyByCompanyId', { companyId: companyId }).then(callback);
	}

	loadDashboard(filters) {
		return this.postRequest('LoadDashboard', filters, true, false);
	}

	getDashboardFilters(entityId) {
		return this.postRequest('GetDashboardFilters', entityId, true, false);
	}

	listParcelamentsPayRoll(campaignId, companyId) {
		return this.getRequest('ListParcelamentsPayRollByCampaignIdAndCompanyId', { campaignId: campaignId, companyId: companyId });
	}

	saveDashboardFiltersToLocalStorage(selectedFilters) {
		let filters = localStorage.getItem(DASHBOARD_FILTERS);
		if (filters)
			filters = JSON.parse(filters);
		else
			filters = {};
		let loginService = new LoginService();
		let user = loginService.getLoggedUser();
		if (user) {
			if (!filters.hasOwnProperty(user))
				filters[user] = {};
			filters[user][selectedFilters.entityId] = selectedFilters;
			console.log(filters);
			localStorage.setItem(DASHBOARD_FILTERS, JSON.stringify(filters));
		}
	}

	loadDashboardFiltersFromLocalStorage(entityId) {
		let filters = localStorage.getItem(DASHBOARD_FILTERS);
		if (filters && entityId) {
			filters = JSON.parse(filters);
			let loginService = new LoginService();
			let user = loginService.getLoggedUser();
			if (user && filters.hasOwnProperty(user) && filters[user].hasOwnProperty(entityId))
				return filters[user][entityId];
		}
		return null;
	}

	clearDashboardFilters(entityId) {
		let filters = localStorage.getItem(DASHBOARD_FILTERS);
		if (filters && entityId) {
			filters = JSON.parse(filters);
			let loginService = new LoginService();
			let user = loginService.getLoggedUser();
			if (user && filters.hasOwnProperty(user) && filters[user].hasOwnProperty(entityId)) {
				let userFilter = filters[user];
				delete userFilter[entityId];
				localStorage.setItem(DASHBOARD_FILTERS, JSON.stringify(filters));
			}
		}
	}
}